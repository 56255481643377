<template>
  <div class="app-container">
    <eHeader  :query="query"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="productName" label="产品名称"/>
      <el-table-column prop="productCode" label="产品编码"/>
      <el-table-column prop="merId" label="商户名称">
        <template slot-scope="scope">
          <span>{{ parseMer(scope.row.merId) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="validDate" label="有效期">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.validDate) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="productDetail" label="产品明细">
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span>{{ scope.row.productStatus==1 ? '启用':'禁用' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作"  align="center">
        <template slot-scope="scope">
          <edit v-if="checkPermission(['ADMIN','PRODUCT_ALL','PRODUCT_EDIT'])" :data="scope.row" :sup_this="sup_this" :merOptions="queryMerOptions"/>
          <el-popover
            v-if="checkPermission(['ADMIN','PRODUCT_ALL','PRODUCT_DELETE'])"
            :ref="scope.row.id"
            placement="top"
            width="180">
            <p>确定{{ scope.row.productStatus==2 ? '启用':'禁用' }}此商户吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="modifyStatus(scope.row)">确定</el-button>
            </div>
            <el-button slot="reference" type="danger" size="mini">{{ scope.row.productStatus==2 ? '启用':'禁用' }}</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initPmsData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/pms/product/header'
import edit from '@/components/pms/product/edit'
import { queryAllMerchantInfos,updateProductInfo } from '@/api/dataPms'
export default {
  components: { eHeader, edit },
  mixins: [initData],
  data() {
    return {
      delLoading: false, sup_this: this,formData:{},
      queryMerOptions: []
    }
  },
  created() {
    this.queryMerOption()
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = '/community/crm/configure/products/queryProductInfoByParams'
      const query = this.query
      const productName = query.productName
      const productCode = query.productCode
      const productStatus=query.productStatus
      const startValidDate = parseTime(query.startValidDate)
      const endValidDate = parseTime(query.endValidDate)
      this.formData = { P: this.page, pageSize: this.size }
      if (productName !== '' && productName !== null) { this.formData['productName'] = productName }
      if (productCode !== '' && productCode !== null) { this.formData['productCode'] = productCode }
      if (productStatus !== '' && productStatus !== null) { this.formData['productStatus'] = productStatus }
      if (startValidDate !== '' && startValidDate !== null) { this.formData['startValidDate'] = startValidDate }
      if (endValidDate !== '' && endValidDate !== null) { this.formData['endValidDate'] = parseTime(endValidDate) }
      this.params = {'content':JSON.stringify(this.formData)}
      return true
    },
    queryMerOption(){
     const params = {'content':JSON.stringify()}
     queryAllMerchantInfos(params).then(res => {
        this.queryMerOptions = res.value
      })
    },
    parseMer(params){
      for (var i = this.queryMerOptions.length - 1; i >= 0; i--) {
        if(this.queryMerOptions[i].id==params){
          return this.queryMerOptions[i].merName
        }
      }
    },
    modifyStatus(info) {
      this.delLoading = true
      info.productStatus=info.productStatus==2 ? '1':'2'
      this.formData = { productInfo: info }
      this.params = {'content':JSON.stringify(this.formData)}
      updateProductInfo(this.params).then(res => {
        this.delLoading = false
        this.$refs[info.id].doClose()
        this.init()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[id].doClose()
        console.log(err.response.data.message)
      })
    }
  }
}
</script>

<style scoped>

</style>
