<template>
  <div class="head-container">
    <!-- 搜索 -->
     <el-input v-model="query.productName" clearable placeholder="输入产品名称" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-input v-model="query.productCode" clearable placeholder="输入产品编码" style="width: 200px;"  class="filter-item" @keyup.enter.native="toQuery"/>
    <el-select v-model="query.productStatus" clearable placeholder="请选择产品状态">
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    <el-date-picker
      class="filter-item"
      v-model="query.startValidDate"
      type="date"
      placeholder="选择开始日期">
    </el-date-picker>
    <el-date-picker
      class="filter-item"
      v-model="query.endValidDate"
      type="date"
      placeholder="选择结束日期">
    </el-date-picker>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <!-- 新增 -->
    <div style="display: inline-block;margin: 0px 2px;">
      <el-button
        v-if="checkPermission(['ADMIN','PRODUCT_ALL','PRODUCT_ADD'])"
        class="filter-item"
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="$refs.form.dialog = true">新增</el-button>
      <eForm ref="form" :is-add="true" :merOptins="merOptins"/>
    </div>

  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
import { parseTime } from '@/utils/index'
import eForm from './form'
// 查询条件
export default {
  components: { eForm },
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      downloadLoading: false,
      statusOptions: [
        {value:'1',label:'启用'},
        {value:'2',label:'禁用'}
      ]
    }
  },
  methods: {
    checkPermission,
    // 去查询
    toQuery() {
      this.$parent.page = 0
      this.$parent.init()
    },
    // 数据转换
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        if (j === 'createTime' || j === 'lastPasswordResetTime') {
          return parseTime(v[j])
        } else if (j === 'enabled') {
          return parseTime(v[j]) ? '启用' : '禁用'
        } else {
          return v[j]
        }
      }))
    }
  }
}
</script>
