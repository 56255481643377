<template>
  <el-dialog :visible.sync="dialog" :title="isAdd ? '新增产品' : '编辑产品'" append-to-body width="700px">
    <el-form ref="form" :model="form" size="small" label-width="120px">
      <el-form-item label="产品名称" prop="productName">
        <el-input v-model="form.productName" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="产品编号" prop="productCode">
        <el-input v-model="form.productCode" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="状态" prop="productStatus">
        <el-radio-group v-model="form.productStatus">
          <el-radio :label="1">启用</el-radio>
          <el-radio :label="2">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="商户名称" prop="merId">
        <el-select v-model="form.merId" placeholder="请选择商户">
        <el-option
          v-for="item in merOptins"
          :key="item.id"
          :label="item.merName"
          :value="item.id">
        </el-option>
      </el-select>
      </el-form-item>
      <el-form-item label="有效期" prop="validDate">
        <el-date-picker
          v-model="form.validDate"
          type="date"
          style="width: 250px;"
          placeholder="选择日期">
        </el-date-picker>
      </el-form-item> 
      <el-form-item label="产品明细" prop="productDetail">
        <el-input v-model="form.productDetail" style="width: 500px;"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addProductInfo, updateProductInfo } from '@/api/dataPms'
import { parseTime } from '@/utils/index'
export default {
  name: 'Form',
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    },
    merOptins: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      dialog: false, loading: false, form: {id: '',productName: '', productCode: '', merId: '',  productStatus:'' , validDate: '' ,productDetail: '',updateBy:'',createBy:''}, 
      rules: {
        productName: [
          { required: true, message: '请输入产品名称', trigger: 'blur' }
        ],
        productCode: [
          { required: true, message: '请输入产品编码', trigger: 'blur' }
        ],
        merId: [
          { required: true, message: '请输入商户编号', trigger: 'blur' }
        ],
        productDetail: [
          { required: true, message: '请输入产品明细', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.isAdd) {
            this.doAdd()
          } else {
            this.doEdit()
          } 
        } else {
          return false
        }
      })
    },
    doAdd() {
      this.formData()
      addProductInfo(this.params).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      this.formData()
      updateProductInfo(this.params).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    formData(){
      const validDate = this.form.validDate
      this.form.validDate=parseTime(validDate)
      if(this.isAdd){
         if(this.form.updateBy=='' || this.form.updateBy==null ) this.form.updateBy='System'
        if(this.form.createBy=='' || this.form.createBy==null ) this.form.createBy='System'
      }
      this.formData = { productInfo: this.form }
      this.params = {'content':JSON.stringify(this.formData)}
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {id: '',productName: '', productCode: '', merId: '',  productStatus:'' , validDate: '' ,productDetail: '',updateBy:'',createBy:''}
    }
  }
}
</script>

<style scoped>

</style>
