<template>
  <div>
    <el-button size="mini" type="success" @click="to">编辑</el-button>
    <eForm ref="form"  :sup_this="sup_this" :is-add="false" :merOptions="merOptions"/>
  </div>
</template>
<script>
import eForm from './form'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    // index.vue 的this 可用于刷新数据
    sup_this: {
      type: Object,
      required: true
    },
    merOptions: {
      type: Array,
      required: true
    }
  },
  methods: {
    to() {
      const _this = this.$refs.form
      _this.form ={ id:this.data.id,productName: this.data.productName, productCode: this.data.productCode, merId: this.data.merId,productStatus:this.data.productStatus,  merStatus:this.data.merStatus, validDate: this.data.validDate ,productDetail: this.data.productDetail,updateBy:this.data.updateBy,createBy:this.data.createBy}
      _this.merOptions=this.merOptions
      _this.dialog = true 
    }
  }
}
</script>

<style scoped>
  div{display: inline-block;margin-right: 3px;}
</style>
